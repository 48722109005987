import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import 'select2';                       // globally assign select2 fn to $ element

export default class extends Controller {
  initialize() {
    $('.select2-container').remove()
    $('.select2').select2({
      allowClear: true
    });
    $('.select2').on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  autocompleteRegionInput(event) {
    const department = event.currentTarget.value
    const regionInput = document.querySelector(`.rm-region`)
    const departmentElement = document.querySelector('[data-departments]');
    const values = departmentElement.dataset.departments
    const region_and_department = JSON.parse(values).find(e => e.includes(department))
    const region_name = region_and_department[1]
    regionInput.value = region_name
    regionInput.dispatchEvent(new Event('change'));
  }

  addCustomSelect2Class(event) {
    const wrapper = event.currentTarget
    const currentClass = wrapper.dataset.customClass
    const value = wrapper.querySelector('select').value
    const newClass = `select2-${value}`
    wrapper.classList.remove(currentClass)
    wrapper.classList.add(newClass)
    wrapper.dataset.customClass = newClass
  }

  submitTurboForm(event) {
    const input = event.currentTarget
    const form = input.form
    form.requestSubmit()
  }
}
