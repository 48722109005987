import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static targets = ["wrapper", "wrappedText", 'wrappedButton']

  connect() {
    useIntersection(this)
  }

  appear() {
    if (this.wrapperTarget.dataset.maxHeight < this.wrapperTarget.scrollHeight) {
      this.wrappedButtonTarget.style.display = 'block'
    }
  }

  expandText(event) {
    const fullHeight = this.wrapperTarget.scrollHeight;
    const isExpanded = event.currentTarget.classList.contains('expand');

    if (isExpanded) {
      this.wrapperTarget.style.height = '120px';
      this.wrapperTarget.style.maxHeight = 'none';
    } else {
      this.wrapperTarget.style.height = fullHeight + 'px';
      this.wrapperTarget.style.maxHeight = fullHeight + 'px';
    }

    event.currentTarget.classList.toggle('expand');
  }
}
