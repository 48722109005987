import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  rotateCaret(event) {
    const caret = event.currentTarget
    if (caret.classList.contains('ordered')) {
      const svg = caret.querySelector("svg")
      svg.classList.toggle('rotate180')
    } else {
      caret.classList.add('ordered')
    }
  }
}
