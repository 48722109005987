import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import 'select2';

// Connects to data-controller="save-user-form"
export default class extends Controller {
  static targets = ["form", "template"]

  connect() {
    const updateFetch = this.updateFetch
    const formTarget = this.formTarget
    const templateTarget = this.templateTarget
    $('.update-active .select2').on("select2:select", function (e) {
      updateFetch(formTarget, templateTarget)
    });
  }

  update() {
    this.updateFetch(this.formTarget, this.templateTarget);
  }

  updateFetch(formTarget, templateTarget) {
    const url = formTarget.action
    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    fetch(url, {
      method: "PUT",
      headers: { "Accept": "text/plain", 'X-CSRF-Token': csrfToken },
      body: new FormData(formTarget)
    })
      .then(response => response.text())
      .then((data) => {
        templateTarget.outerHTML = data
      })
  }
}
