import { Controller } from "@hotwired/stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"


export default class extends Controller {
  static values = { apiKey: String, answer: String }

  static targets = ["address"]

  connect() {
    if(this.hasAddressTarget) {
      this.geocoder = new MapboxGeocoder({
        accessToken: this.apiKeyValue,
        types: "country,region,place,postcode,locality,neighborhood,address",
        placeholder: "Search an addresss"
      })
      this.geocoder.addTo(this.element)
      this.input = this.geocoder._inputEl
      this.input.addEventListener('change', (event) => {this.#fillInputValue(event)})
      if (this.answerValue !== "") this.input.value = this.answerValue
      this.geocoder.on("result", event => this.#setInputValue(event))
      this.geocoder.on("clear", () => this.#clearInputValue())
    }
  }

  #fillInputValue(event) {
    this.addressTarget.value = event.currentTarget.value
  }

  disconnect() {
    if (this.hasAddressTarget) {
      this.geocoder.onRemove()
    }
  }

  #setInputValue(event) {
    this.#setAdressValues(event.result)
    this.addressTarget.value = event.result["place_name"]
  }

  #setAdressValues(result) {
    const context = result.context;
    this.#autocompleteInput("department", "region", context)
    this.#autocompleteInput("localization", "place", context)
  }

  #autocompleteInput(class_name, code, context) {
    this.class_name = context.find(e => e.id.includes(code))?.text;
    const input = document.querySelector(`.rm-${class_name}`)
    if (input) {
      if (typeof (this.class_name) !== "undefined") {
        this.#autocompleteRegionInput("region", this.class_name)
        input.value = this.class_name
        input.dispatchEvent(new Event('change'));
      } else {
        input.value = ""
      }
    }
  }

  #autocompleteRegionInput(region, department) {
    const regionInput = document.querySelector(`.rm-${region}`)
    const departmentElement = document.querySelector('[data-departments]');
    if (departmentElement) {
      const values = departmentElement.dataset.departments
      const region_and_department = JSON.parse(values).find(e=> e.includes(department))
      const region_name = region_and_department[1]
      regionInput.value = region_name
      regionInput.dispatchEvent(new Event('change'));
    }
  }

  #clearInputValue() {
    this.addressTarget.value = ""
  }
}
