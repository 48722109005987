import NestedForm from 'stimulus-rails-nested-form';
import $ from 'jquery';
import 'select2';

export default class extends NestedForm {
  add(event) {
    super.add(event);
    $('.select2').select2();
    $('.select2').on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

}
