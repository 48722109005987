import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-input"
export default class extends Controller {
  static targets = ["input", "deleteButton", "preview", "check", "name", "fileUploadDate"]

  submitForm(event) {
    event.currentTarget.form.submit()
  }

  insertFile(event) {
    const input = event.currentTarget
    const file = input.files[0]
    if(file) {
      const fileUrl = URL.createObjectURL(file)
      this.deleteButtonTarget.classList.remove("d-none")
      this.previewTarget.setAttribute('href', fileUrl)
      this.previewTarget.classList.remove('d-none')
      this.checkTarget.classList.remove("answer-check-empty")
      this.checkTarget.classList.add("answer-check", "bg-primary")
      this.nameTarget.innerHTML = file.name
    }
  }

  deleteFile(event) {
    this.deleteButtonTarget.classList.add("d-none")
    this.previewTarget.setAttribute('href', '#')
    this.previewTarget.classList.add('d-none')
    this.checkTarget.classList.remove("answer-check", "bg-primary")
    this.checkTarget.classList.add("answer-check-empty")
    this.nameTarget.innerHTML = "Document non transmis"
    if (this.hasFileUploadDateTarget) this.fileUploadDateTarget.classList.add('d-none')
  }
}
