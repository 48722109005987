import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus"
import { French } from "flatpickr/dist/l10n/fr.js"

export default class extends Controller {
  static targets = ["flatpickr", 'flatpickrDatetime']
  connect() {
    if (this.hasFlatpickrTarget) {
      flatpickr(this.flatpickrTarget, {
        "dateFormat": "d/m/Y",
        "locale": French
      });
    }

    if (this.hasFlatpickrDatetimeTarget) {
      flatpickr(this.flatpickrDatetimeTarget, {
        dateFormat: "d/m/Y H:i",
        enableTime: true,
        locale: French
      });
    }

  }
}
