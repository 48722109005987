import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = ["modal"]

  open() {
    const modal = this.modalTarget;
    modal.classList.add("show");
    modal.style.display = "block";
    const backdropEl = document.createElement('div');
    backdropEl.classList.add('modal-backdrop', 'fade', 'show');
    document.body.appendChild(backdropEl);
    $('#ofqj-modal .select2').select2({
      dropdownParent: modal
    });
  }

  close() {
    const modal = this.modalTarget;
    modal.classList.remove("show");
    modal.style.display = "none";
    const backdropEl = document.querySelector('.modal-backdrop')
    backdropEl.remove()
  }
}
