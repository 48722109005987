import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"]

  activeTab(event) {
    this.tabTargets.forEach(this.#inactiveTab)
    event.currentTarget.classList.add('active')
  }

  #inactiveTab(tab) {
    tab.classList.remove('active')
  }
}
