import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "check"]

  toggle(event) {
    this.inputTargets.forEach((input)=> {
      if (input != event.currentTarget) {
        input.checked = false
      }
    })
  }

  labelChange(event) {
    this.#removeAllCheckClasses(this.checkTarget)
    this.checkTarget.classList.add(event.currentTarget.value)
  }

  #removeAllCheckClasses(element) {
    element.classList.remove('denied', 'pending_review', 'no_content', 'completed', 'relaunch', 'ofqj_completed')
  }
}
