import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {
  checkFormSection(event) {
    event.preventDefault();
    const sectionId = event.currentTarget.dataset.sectionId
    const section = document.getElementById(`section-${sectionId}`)
    const userTrackingQuestions = section.querySelectorAll(`.utk-section-${sectionId}`)
    const value = 'completed'
    userTrackingQuestions.forEach((utk) => {
      const select2Instance = $(utk).data('select2')
      if (utk.value === 'pending_review' && select2Instance) {
        $(utk).val(value).trigger('change.select2');
        utk.value = value;
        const wrapper = utk.parentNode;
        wrapper.dataset.customClass = `select2-${value}`;
        utk.dispatchEvent(new Event('change'));
        wrapper.dispatchEvent(new Event('change'));
      }
    })
  }
}
