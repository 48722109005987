import { Controller } from "@hotwired/stimulus"
import {Modal} from 'bootstrap'
export default class extends Controller {
  static targets = ['modal'];
  connect() {
    var openModal = this.modalTarget;
    if (openModal) {
      var myModal = new Modal(openModal, {});
      myModal.show();
    }
  }
}
