import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "image" ]

  preview(e) {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      let readers = [];
      readers.push(this.#readFileAsDataUrl(e.currentTarget.files[0]));
      Promise.all(readers).then((urls) => {
        if(urls[0]) {
          this.imageTarget.style.backgroundImage = "url(" + urls[0] + ")";
          this.imageTarget.classList.add("img-input-filled")
          this.imageTarget.classList.remove("img-input-empty")
        }
      });
    }
  }

  #readFileAsDataUrl(file){
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }
}
