import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", 'tab']

  toggle(event) {
    this.menuTarget.classList.toggle('active')
  }

  activetab(event) {
    this.changeActiveTab(this.tabTargets, event.target)
  }

  changeActiveTab(tabs, target) {
    tabs.find(tab => tab.classList.contains('active'))
      .classList.remove('active');
    target.classList.add('active')
  }


}
